import React from "react"
import Section from "../global/Section"
import Banner from "../../images/iser/banner.png"
import logo1 from "../../images/iser/icon1.png"
import logo2 from "../../images/iser/icon2.png"
import logo3 from "../../images/iser/icon3.png"
import logo4 from "../../images/iser/icon4.png"
import wireframe1 from "../../images/iser/wireframe1.png"
import wireframe2 from "../../images/iser/wireframe2.png"
import expenserReport1 from "../../images/iser/expenseReport1.png"
import expenserReport2 from "../../images/iser/expenseReport2.png"
import expenserReport3 from "../../images/iser/expenseReport3.png"
import expenserReport4 from "../../images/iser/expenseReport4.png"
import serverBio1 from "../../images/iser/serverBio1.png"
import serverBio2 from "../../images/iser/serverBio2.png"
import serverBio3 from "../../images/iser/serverBio3.png"
import serverBio4 from "../../images/iser/serverBio4.png"
import opc1 from "../../images/iser/opc1.png"
import opc2 from "../../images/iser/opc2.png"
import opc3 from "../../images/iser/opc3.png"
import opc4 from "../../images/iser/opc4.png"
import home1 from "../../images/iser/home1.png"
import home2 from "../../images/iser/home2.png"

export default function iser() {
  return (
    <>
      <Section extraMargin={"8rem"}>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="font-w-extrabold">
              ISER - Internal Service Expense Report
            </h2>
            <p>An internal web tool for server management.</p>
            <img src={Banner} width="100%" className="pt-5" />
          </div>
        </div>
      </Section>
      <Section>
        <div className="row">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">Objective</h4>
            <p className="d-grey pt-5">
              In SAP, many groups or organizations are continuously charged for
              servers that do not exist anymore. Hence, dead servers. To address
              this problem, we break down the project into three phases. See the
              project scope below.
            </p>
          </div>
        </div>
      </Section>
      <Section>
        <div className="row">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">Project Scope</h4>
            <div className="row pt-5">
              <div className="col-lg-4 col-md-4 col-sm-12 pt-2">
                <p className="d-grey">1. Expense Transparency</p>
                <p className="content-small l-grey pt-2">
                  Create a transparent expense report for the spending on
                  internal servers through all organization from top to bottom.
                </p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 pt-2">
                <p className="d-grey">2. Server Bio</p>
                <p className="content-small l-grey pt-2">
                  Establish profiles for each servers, so we could have a better
                  understanding of server usage and background information.
                </p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 pt-2">
                <p className="d-grey">3. Call for Actions</p>
                <p className="content-small l-grey pt-2">
                  Generate tickets for multiple purposes, such as update the
                  servers’ info or terminate the inactive servers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section>
        <div className="row">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">
              Design Goal - A suite for internal servers management
            </h4>
            <p className="d-grey pt-5">
              To achieve all the scopes of the project, we decide to create a
              tool for internal server management, which includes spending
              reports, servers’ bio, and a ticket system. The spending report is
              our starting point since we want to know which organizations are
              spending money on servers, and how many they are spending monthly,
              which leads to more questions - Are those servers active or
              inactive? What's the utility of those servers? If the server is
              dormant, can we shut it down to save money?
            </p>
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-lg-3 col-md-3 col-sm-12 pt-2">
            <img src={logo1} width="80%" />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 pt-2">
            <img src={logo2} width="80%" />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 pt-2">
            <img src={logo3} width="80%" />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 pt-2">
            <img src={logo4} width="80%" />
          </div>
          <div className="col-sm-12">
            <p className="d-grey pt-5">
              But why three apps? Because we have a different level of
              permission or access to different users. For example, high-level
              personnel can access the expense report while the server owner
              might only have permission to search through the server profile.
            </p>
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-sm-12">
            <h6 className="font-w-extrabolditalic d-grey text-center">
              A fun fact for ISER’s logo design - It’s sound like ICE, let’s
              make an ice cube as our app logo!
            </h6>
          </div>
        </div>
      </Section>
      <Section>
        <div className="row">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">Design Language 🎨</h4>
            <p className="d-grey pt-5">
              To keep consistency for this three in one suite, we'll need a
              universal style guide and design language, which includes the
              layout, UI style, icons, etc.
            </p>
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <img
              src={wireframe1}
              width="100%"
              className="imgSize-shadowStyle"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <img
              src={wireframe2}
              width="100%"
              className="imgSize-shadowStyle"
            />
          </div>
        </div>
      </Section>
      <Section>
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">Expense Report</h4>
            <p className="d-grey pt-5">
              In the Expense Report, high-level staff can monitor the expense on
              the internal servers through all SAP cost centers from top to
              bottom. This can help the users to monitor the spending trends,
              budget allocations, and spending deep dive, etc.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <img
              src={expenserReport1}
              width="100%"
              className="imgSize-shadowStyle"
            />
            <p className="l-grey pt-4 text-center">
              Basic background info for the organization.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <img
              src={expenserReport2}
              width="100%"
              className="imgSize-shadowStyle"
            />
            <p className="l-grey pt-4 text-center">
              Monthly bar chart of aggregated cost and number of servers under
              the organization.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 pt-2">
                <img
                  src={expenserReport3}
                  width="100%"
                  className="imgSize-shadowStyle"
                />
                <p className="l-grey pt-4 text-center">
                  Showing children organizations spending in a list and pie
                  chart.
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pt-2">
                <img
                  src={expenserReport4}
                  width="100%"
                  className="imgSize-shadowStyle"
                />
                <p className="l-grey pt-4 text-center">
                  Showing all servers that under the specific organization.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section>
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">Server Bio</h4>
            <p className="d-grey pt-5">
              The Server Bio shows the server basic information such as the
              server owner(OPC), types, ping, etc. It also reveals the usage of
              each internal server, which helps the users to take action and
              make decisions for those servers. For example, generate a ticket
              for update the server info, or even terminate the server.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <img
              src={serverBio1}
              width="100%"
              className="imgSize-shadowStyle"
            />
            <p className="l-grey pt-4 text-center">
              Showing all servers that own by an OPC.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <img
              src={serverBio2}
              width="100%"
              className="imgSize-shadowStyle"
            />
            <p className="l-grey pt-4 text-center">
              Showing tickets belong to the OPC.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 pt-2">
                <img
                  src={serverBio3}
                  width="100%"
                  className="imgSize-shadowStyle"
                />
                <p className="l-grey pt-4 text-center">
                  Showing servers that owns by me as a OPC.
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pt-2">
                <img
                  src={serverBio4}
                  width="100%"
                  className="imgSize-shadowStyle"
                />
                <p className="l-grey pt-4 text-center">
                  Resolve the ticket through the tickets.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section>
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">
              OPC(Overall Person in Charged) Ticket
            </h4>
            <p className="d-grey pt-5">
              We generated tickets as actionable items for the overall person in
              charge. They'll receive an email with a CTA directing them to the
              OPC ticket app. Then, they'll resolve the ticket by starting the
              procedure according to the ticket.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <img src={opc1} width="100%" className="imgSize-shadowStyle" />
            <p className="l-grey pt-4 text-center">
              Basic background info for individual server.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <img src={opc2} width="100%" className="imgSize-shadowStyle" />
            <p className="l-grey pt-4 text-center">
              Showing all cost centers break down.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 pt-2">
                <img src={opc3} width="100%" className="imgSize-shadowStyle" />
                <p className="l-grey pt-4 text-center">Utility information.</p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pt-2">
                <img src={opc4} width="100%" className="imgSize-shadowStyle" />
                <p className="l-grey pt-4 text-center">
                  Showing the ticket which belongs to this server.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section>
        <div className="row">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">Main page</h4>
            <p className="d-grey pt-5">
              The landing page allows users to do a global search across these
              three applications by entering the UUID, I-numbers, or server
              name. Users can also go to the most relevant application directly
              and do the search in that application.
            </p>
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <img src={home1} width="100%" className="imgSize-shadowStyle" />
            <p className="l-grey pt-4 text-center">
              ISER’s landing page where allow users do a global search cross
              three apps or an option
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <img src={home2} width="100%" className="imgSize-shadowStyle" />
            <p className="l-grey pt-4 text-center">Sign in page</p>
          </div>
        </div>
      </Section>
      <Section>
        <div className="row">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">Final thoughts</h4>
            <p className="d-grey py-5">
              We are still at the early stage of this project. We have our
              expense report application ready for assisting our users to
              monitor the monthly expense However, there are still many
              challenges that we are trying to figure out. Such as OPC has
              already left the company and we have no clue what is running on
              the instances owned by him/her; therefore, we cannot just
              terminate those servers.
            </p>
          </div>
        </div>
      </Section>
    </>
  )
}
