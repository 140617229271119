import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/iser/iser"

export default function iser() {
  return (
    <Layout>
      <SEO title="ISER" />
      <Content />
    </Layout>
  )
}
